@import '~/styles/utils';

.carouselImage {
  display: flex;
  width: 100%;
  height: 100%;
  background: $gradient3;
  padding: 1%;
  border-radius: 50%;
  aspect-ratio: 1 / 1;
  img {
    pointer-events: none;
    user-select: none;
    width: 100%;
    height: 100%;
    clip-path: circle(50% at 50% 50%);
    object-fit: cover;
  }
}
