@import '~/styles/utils';

.mask {
  width: 100%;
  overflow: hidden;
  background: $color-background;
  transform: translate3d(0, 0, 0);
}

.copyContainer {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 5;
  text-align: center;
  gap: 15rem;
}

.eyebrow {
  @include body;
}

.heading {
  @include h2;
}

.eyebrow,
.heading {
  width: 100%;
  margin: 0 auto;
  padding: 0 $side-padding;
  @include landscape {
    max-width: n-grid-col-width(8);
  }
}
